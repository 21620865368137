import React, { useContext, useState, useEffect } from "react"
import Layout from "@/components/layout"
import { SEO } from "@/components"
import { Policy } from "@/new_components"

function Index() {
  return (
    <Layout menuBlack={true}>
      <SEO page="Privacy_Policy" />
      <Policy policyType='pp' />
    </Layout>
  )
}

export default Index
